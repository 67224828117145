import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const BackButtonManager = () => {
    const location = useLocation();

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            // Проверяем, что мы не на главной странице
            if (location.pathname !== '/games') {
                window.Telegram.WebApp.BackButton.show();
                window.Telegram.WebApp.BackButton.onClick(() => {
                    window.history.back();
                });
            } else {
                window.Telegram.WebApp.BackButton.hide();
            }
        }

        // Очищаем обработчик при размонтировании
        return () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.BackButton.offClick();
            }
        };
    }, [location]);

    return null; // Этот компонент не рендерит UI, он только управляет логикой кнопки "Назад"
};

export default BackButtonManager;
