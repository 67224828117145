import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GameCard from '../components/GameCard';
import Loading from '../components/Loading'; // Импортируем компонент спиннера
import styles from './GameList.module.sass';
import { Link } from 'react-router-dom';

const GameList = () => {
    const [games, setGames] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Состояние для загрузки

    useEffect(() => {
        const initTelegramApp = () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.ready(); 
                window.Telegram.WebApp.expand(); 
            } else {
                console.error("Telegram WebApp API недоступен.");
            }
        };

        const fetchGames = async () => {
            try {
                const response = await axios.get('https://server.gemchik.com/api/games');
                setGames(response.data);
            } catch (error) {
                setError('Не удалось загрузить список игр. Попробуйте позже.');
            } finally {
                setLoading(false); // Отключаем загрузку после получения данных
            }
        };

        initTelegramApp();
        fetchGames();
    }, []);

    // Если идет загрузка, отображаем спиннер
    if (loading) {
        return <Loading />;
    }

    return (
        <main className={styles['main']}>
            <header className={styles['header']}>
                <h1 className={styles['h1']}>Выберите игру</h1>
                <Link to={`/profile`} className={styles['link-perfil']}><img src="/icons/profile.svg" className={styles['icon-perfil']}/></Link>
            </header>
            {error && (
                <div className={styles['error-message']}>
                    <p>{error}</p>
                </div>
            )}
            <div className={styles['game-list']}>
                {games.map((game) => (
                    <GameCard key={game._id} game={game} />
                ))}
            </div>
        </main>
    );
};

export default GameList;
