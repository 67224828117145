import React, { useState } from 'react'
import styles from './Shop.module.sass'

const Shop = () => {
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [email, setEmail] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleBuyClick = (product) => {
    setSelectedProduct(product)
    setIsModalOpen(true)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    alert(`Платеж через FreeKassa для ${selectedProduct} на почту ${email}`)
    setIsModalOpen(false)
  }

  const products = [
    { name: '14 зем', price: '140 руб.' },
    { name: '28 зем', price: '280 руб.' },
    { name: '58 зем', price: '580 руб.' },
    { name: '10 гем', price: '100 руб.' },
    { name: '25 гем', price: '250 руб.' },
    { name: '50 гем', price: '500 руб.' },
    { name: '100 гем', price: '1000 руб.' },
    { name: '200 гем', price: '2000 руб.' },
    { name: '500 зем', price: '5000 руб.' },
    { name: '1000 зем', price: '10000 руб.' },
    { name: '500 гем', price: '5000 руб.' },
    { name: '1000 гем', price: '10000 руб.' },
    { name: 'VIP доступ', price: '1500 руб.' },
    { name: 'Premium доступ', price: '3000 руб.' },
    { name: 'Exclusive доступ', price: '5000 руб.' },
    { name: 'Секретный товар', price: '10000 руб.' },
    { name: 'Special Edition гем', price: '1500 руб.' }
  ]

  return (
    <div className={styles.container}>
      <h1>Магазин донатов Gemchik</h1>

      <div className={styles.products}>
        {products.map((product) => (
          <div key={product.name} className={styles.product}>
            <h2>{product.name}</h2>
            <p className={styles.price}>Цена: {product.price}</p>
            <button className={styles.buyButton} onClick={() => handleBuyClick(product.name)}>
              Купить
            </button>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h2>Введите вашу почту</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Ваш email"
                className={styles.emailInput}
                required
              />
              <h3>Выберите платежную систему:</h3>
              <div className={styles.paymentMethod}>
                <label>
                  <input type="radio" name="payment" value="freekassa" checked readOnly />
                  FreeKassa
                </label>
              </div>
              <button type="submit" className={styles.submitButton}>Оплатить</button>
            </form>
            <button className={styles.closeButton} onClick={() => setIsModalOpen(false)}>Закрыть</button>
          </div>
        </div>
      )}

      <footer className={styles.footer}>
        <p>При поддержке платежной системы FreeKassa</p>
        <p>Для поддержки: <a href="https://t.me/Gem4ik_ContactBot" target="_blank" rel="noopener noreferrer">https://t.me/Gem4ik_ContactBot</a></p>
      </footer>
    </div>
  )
}

export default Shop
