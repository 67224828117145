import React from 'react';
import styles from './ProductCard.module.sass';

const ProductCard = ({ product }) => {
    // Функция для замены символов подчеркивания на пробелы
    const formatGameName = (game) => {
        return game.replace(/_/g, ' ');
    };

    return (
        <div className={styles['product-card']}>
            <img src={product.imageUrl} alt={product.name} className={styles['product-image']} />
            <div className={styles['product-info']}>
                <div className={styles['product-text']}>
                    <h2 className={styles['product-name']}>{product.name}</h2>
                    <p className={styles['product-game']}>{formatGameName(product.game)}</p>
                </div>
                <p className={styles['product-price']}>{product.price} руб</p>
            </div>
        </div>
    );
};

export default ProductCard;
