import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './GameProducts.module.sass';
import { useParams, Link } from 'react-router-dom'; // Импортируем Link для перехода
import ProductCard from '../components/ProductCard';
import Loading from '../components/Loading'; // Импортируем компонент спиннера

const GameProducts = () => {
    const { gameId } = useParams();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true); // Состояние для отслеживания загрузки

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`https://server.gemchik.com/api/products/${gameId}`);
                setProducts(response.data);
            } catch (error) {
                console.error('Ошибка при загрузке списка товаров:', error);
            } finally {
                setLoading(false); // Отключаем загрузку после получения данных
            }
        };

        fetchProducts();
    }, [gameId]);

    const formatGameName = (game) => {
        return game.replace(/_/g, ' ');
    };

    // Если идет загрузка, отображаем спиннер
    if (loading) {
        return <Loading />;
    }

    return (
        <main className={styles['main']}>
            <h1 className={styles['h1']}>{formatGameName(gameId)}</h1>
            <div className={styles['product-list']}>
                {products.map((product) => (
                    <Link to={`/product/${product._id}`} key={product._id}>
                        <ProductCard product={product} />
                    </Link>
                ))}
            </div>
        </main>
    );
};

export default GameProducts;
