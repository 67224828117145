import './styles/default/basic.css';
import './styles/default/bootstrap-grid.min.css';
import './styles/default/bootstrap-reboot.min.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GameList from './pages/GameList';
import GameProducts from './pages/GameProducts';
import ProductDetails from './pages/ProductDetails';
import BackToBot from './pages/BackToBot';
import Profile from './pages/Profile';
import Shop from './pages/Shop';
import ErrorBoundary from './ErrorBoundary';
import BackButtonManager from './components/BackButtonManager';

function App() {
    return (
        <Router>
            <ErrorBoundary>
            <BackButtonManager />
            <Routes>
                <Route path="/games" element={<GameList />} />
                <Route path="/games/:gameId" element={<GameProducts />} />
                <Route path="/product/:productId" element={<ProductDetails />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/back-to-bot" element={<BackToBot />} />
                <Route path="/" element={<Shop />} />
                <Route path="/shop" element={<Shop />} />
            </Routes>
            </ErrorBoundary>
        </Router>
    );
}

export default App;
