import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styles from './ProductDetails.module.sass';
import Loading from '../components/Loading';

const paymentMethods = [
  { name: 'Оплата QR/СБП', value: 'FreeKassaSBP', icon: '/icons/payments/FreeKassaSBP.svg' },
  { name: 'Оплата Перевод', value: 'NicePay', icon: '/icons/payments/NicePay2.svg' },
  { name: 'Оплата Карта', value: 'FreeKassaCard', icon: '/icons/payments/FreeKassaCard.svg' },
];

const validationRules = {
  username: Yup.string().min(3, 'Минимум 3 символа').max(30, 'Максимум 30 символов').required('Обязательное поле'),
  password: Yup.string().min(3, 'Минимум 3 символа').max(30, 'Максимум 30 символов').required('Обязательное поле'),
  Zepeto_ID: Yup.string().matches(/^[a-zA-Z0-9_.]{3,20}$/, 'Можно использовать только английские буквы, цифры 0-9, точку и нижнее подчеркивание').required('Обязательное поле'),
  Genshin_Impact_UID: Yup.string().max(15, 'Максимум 15 символов').required('Обязательное поле'),
  Supercell_ID: Yup.string().min(3, 'Минимум 3 символа').max(50, 'Максимум 50 символов').required('Обязательное поле'),
  Free_Fire_UID: Yup.string().matches(/^\d+$/, 'Только цифры').min(3, 'Минимум 3 символа').max(25, 'Максимум 25 символов').required('Обязательное поле'),
  PUBG_Mobile_ID: Yup.string().matches(/^\d+$/, 'Только цифры').min(3, 'Минимум 3 символа').max(19, 'Максимум 19 символов').required('Обязательное поле'),
};

const ProductDetails = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [userTelegramId, setUserTelegramId] = useState(null);
  const [userTelegramUsername, setUserTelegramUsername] = useState(null);
  const [userIP, setUserIP] = useState(null);
  const [paymentLink, setPaymentLink] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState('Оплатить'); // Текст кнопки

  useEffect(() => {
    const initTelegram = () => {
      if (window.Telegram?.WebApp?.initDataUnsafe?.user) {
        const user = window.Telegram.WebApp.initDataUnsafe.user;
        setUserTelegramId(user.id);
        setUserTelegramUsername(user.username);
      } else {
        setError('Не удалось получить данные из Telegram.');
      }
    };
    initTelegram();

    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://server.gemchik.com/api/product/${productId}`);
        setProduct(response.data);
      } catch (error) {
        setError('Ошибка при загрузке товара.');
      } finally {
        setLoading(false);
      }
    };

    const fetchIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setUserIP(response.data.ip);
      } catch (error) {
        console.error('Ошибка при получении IP:', error);
      }
    };

    fetchProduct();
    fetchIP();
  }, [productId]);

  const validationSchema = Yup.object(
    product?.requiredInfo.reduce((acc, info) => {
      if (validationRules[info]) {
        acc[info] = validationRules[info];
      }
      return acc;
    }, {})
  );

  if (error) {
    return <div>{error}</div>;
  }

  if (loading) {
    return <Loading />;
  }

  if (!product) {
    return <div>Товар не найден.</div>;
  }

  if (step === 1) {
    return (
      <main className={styles['main']}>
        <div className={styles['product-container']}>
          <img src={product.imageUrl} alt={product.name} className={styles['product-image']} />
          <div className={styles['product-details']}>
            <h1 className={styles['h1']}>{product.name}</h1>
            <p className={styles['price']}>Цена: <span>{product.price} ₽</span></p>
            <button className={styles['buy-button']} onClick={() => setStep(2)}>
              Купить
            </button>
          </div>
        </div>
      </main>
    );
  }

  if (step === 2 && product.requiredInfo.length > 0) {
    return (
      <main className={styles['main']}>
        <Formik
          initialValues={product.requiredInfo.reduce((acc, info) => ({ ...acc, [info]: '' }), {})}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setStep(3);
            localStorage.setItem('requiredInfo', JSON.stringify(values));
          }}
        >
          {({ isSubmitting }) => (
            <Form className={styles['form']}>
              <h2 className={styles['h2']}>Заполните данные</h2>
              {product.requiredInfo.map((info) => (
                <div key={info} className={styles['form-group']}>
                  <div className={styles['label-box']}><label htmlFor={info} className={styles['label']}>{info}</label></div>
                  <Field name={info} type="text" className={styles['input']} />
                  <ErrorMessage name={info} component="div" className={styles['error']} />
                </div>
              ))}
              <button className={styles['form-button']} type="submit" disabled={isSubmitting}>
                Продолжить
              </button>
            </Form>
          )}
        </Formik>
      </main>
    );
  }

  if (step === 3) {
    const handleOrderSubmit = async () => {
      try {
        setIsButtonDisabled(true); // Блокируем кнопку сразу после нажатия
        setButtonText('Подождите, пожалуйста...'); // Изменяем текст кнопки

        const requiredInfo = JSON.parse(localStorage.getItem('requiredInfo'));

        const orderData = {
          userTelegramId,
          userTelegramUsername,
          userIP,
          price: product.price,
          paymentMethod: selectedPaymentMethod,
          product: {
            productId: product._id,
            name: product.name,
            game: product.game,
            requiredInfo: requiredInfo
          }
        };

        const response = await axios.post('https://server.gemchik.com/api/orders/create', orderData);

        if (response.data.paymentLink) {
          setPaymentLink(response.data.paymentLink); // Сохраняем ссылку на оплату
          window.Telegram.WebApp.openLink(response.data.paymentLink); // Открываем ссылку с помощью Telegram Web App API
          setStep(4); // Переходим на шаг 4 сразу после открытия ссылки
        } else {
          alert('Ошибка при инициализации платежа.');
          setIsButtonDisabled(false); // Разблокируем кнопку в случае ошибки
          setButtonText('Оплатить'); // Восстанавливаем текст кнопки
        }
      } catch (error) {
        console.error('Ошибка при создании заказа:', error);
        alert('Произошла ошибка при создании заказа.');
        setIsButtonDisabled(false); // Разблокируем кнопку в случае ошибки
        setButtonText('Оплатить'); // Восстанавливаем текст кнопки
      }
    };

    return (
      <main className={styles['main']}>
        <div className={styles['payment-container']}>
          <h2 className={styles['h2']}>Выберите способ оплаты</h2>
          <p className={styles['warning']}>Вы будете перенаправлены на сторонний сервис для оплаты.</p>
          <p className={styles['payment-price']}>Сумма к оплате: <span>{product.price} руб</span></p>
          <div className={styles['payment-methods']}>
            {paymentMethods.map((method) => (
              <div
                key={method.value}
                className={`${styles['payment-method']} ${selectedPaymentMethod === method.value ? styles['selected'] : ''}`}
                onClick={() => setSelectedPaymentMethod(method.value)}
              >
                <span className={styles['payment-method-text']}>{method.name}</span>
                <div className={styles['payment-method-icon-box']}><img src={method.icon} alt={method.name} className={styles['payment-method-icon']} /></div>
              </div>
            ))}
          </div>
          {selectedPaymentMethod && (
            <button
              className={styles['buy-button']}
              onClick={handleOrderSubmit}
              disabled={isButtonDisabled} // Блокируем кнопку, если она уже заблокирована
            >
              {buttonText} {/* Используем состояние для текста кнопки */}
            </button>
          )}
        </div>
      </main>
    );
  }

  if (step === 4) {
    return (
      <main className={styles['main']}>
        <div className={styles['redirect-container']}>
          <div className={styles['redirect-text']}>
          После оплаты, пожалуйста, вернитесь в чат с ботом Telegram. В некоторых случаях нам могут понадобиться дополнительные данные, о чем мы обязательно уведомим вас в сообщении.
          </div>
          <div className={styles['redirect-buttons']}>
            <button className={styles['redirect-button']} onClick={() => window.Telegram.WebApp.openLink(paymentLink)}>
              Страница оплаты
            </button>
            <button className={styles['redirect-button']} onClick={() => window.Telegram.WebApp.close()}>
              Закрыть приложение
            </button>
          </div>
        </div>
      </main>
    );
  }

  return null;
};

export default ProductDetails;
