import React from 'react';
import styles from './BackToBot.module.sass';

const BackToBot = () => {
  return (
    <div className={styles.container}>
      <div className={styles.messageBox}>
        <h1 className={styles.message}>Вернитесь обратно в бот Telegram</h1>
      </div>
    </div>
  );
};

export default BackToBot;
