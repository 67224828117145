import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Profile.module.sass';
import Loading from '../components/Loading'; // Импортируем компонент спиннера

const getStatusText = (status) => {
    switch (status) {
        case 'pending':
            return 'Не оплачен';
        case 'inProgress':
            return 'Выполняется';
        case 'completed':
            return 'Завершён';
        case 'fail':
            return 'Ошибка';
        case 'canceled':
            return 'Отменён';
        default:
            return status;
    }
};

const getStatusClass = (status) => {
    switch (status) {
        case 'pending':
            return styles['status-pending'];
        case 'inProgress':
            return styles['status-inProgress'];
        case 'completed':
            return styles['status-completed'];
        case 'fail':
            return styles['status-fail'];
        case 'canceled':
            return styles['status-canceled'];
        default:
            return '';
    }
};

const Profile = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true); // Состояние для отслеживания загрузки
    const [error, setError] = useState(null);

    useEffect(() => {
        // Получаем данные пользователя из Telegram WebApp
        const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
        const { id: userTelegramId, username: userTelegramUsername } = initDataUnsafe.user;

        setUserInfo({ userTelegramId, userTelegramUsername });

        // Загружаем заказы пользователя
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`https://server.gemchik.com/api/orders/history/${userTelegramId}`);
                setOrders(response.data);
            } catch (error) {
                setError('Ошибка при загрузке истории заказов.');
            } finally {
                setLoading(false); // Отключаем загрузку после получения данных
            }
        };

        fetchOrders();
    }, []);

    // Отображение спиннера при загрузке
    if (loading) {
        return <Loading />;
    }

    return (
        <main className={styles['main']}>
            <div className={styles['profile-container']}>
                {userInfo && <h1 className={styles['h1']}>Здравствуйте, @{userInfo.userTelegramUsername}!</h1>}
                <h2 className={styles['h2']}>Ваша история заказов:</h2>
                {orders.length > 0 ? (
                    <div className={styles['order-list']}>
                        {orders.map((order) => (
                            <div key={order.orderNumber} className={styles['order-card']}>
                                <div className={styles['order-header']}>
                                    <p className={styles['order-number']}>Заказ #<span>{order.orderNumber}</span></p>
                                    <p className={`${styles['order-status']} ${getStatusClass(order.status)}`}>
                                        {getStatusText(order.status)}
                                    </p>
                                </div>
                                <p><strong>Игра:</strong> {order.product.game.replace(/_/g, ' ')}</p>
                                <p><strong>Товар:</strong> {order.product.name}</p>
                                <p><strong>Цена:</strong> {order.price} ₽</p>
                                <p><strong>Дата заказа:</strong> {new Date(order.createdAt).toLocaleDateString()}</p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className={styles['no-orders']}>У вас пока нет заказов.</p>
                )}
            </div>
        </main>
    );
};

export default Profile;
