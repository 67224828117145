import React from 'react';
import { Link } from 'react-router-dom'; // Импортируем Link
import styles from './GameCard.module.sass';

const GameCard = ({ game }) => {
    return (
        <Link to={`/games/${game.game}`} className={styles['game-card']}>
            <img src={game.imageUrl} alt={game.name} />
            <h2>{game.name}</h2>
        </Link>
    );
};

export default GameCard;
